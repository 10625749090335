import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Table from './OSPTable';
import {
  getVendorSites,
  searchPOBlk,
  searchPOStandard,
  checkDup,
  getOSPJobDetails,
  getSites,
  getJobDetails,
  transits,
} from '../../utils/apis';
import { company_name, vendor_id } from '../../utils';
import { DataContext } from '../../ContextData';
import { useSnackbar } from 'notistack';
import ReactLoading from 'react-loading';
import { getUserType } from '../../utils';
import _ from 'lodash';
import DeliveryPDF from './DelieveryPDFOSP';
import { ListItemText } from '@material-ui/core';
import ListboxComponent from '../../components/VirtualAutoComplete';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: 10,
    top: 0,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function PO(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = React.useContext(DataContext);
  const [company, setCompany] = React.useState('');
  const [job_no, setJob_no] = React.useState('');
  const [po_no, setPo_no] = React.useState('');
  const [site, setSite] = React.useState(null);
  // {VENDOR_SITE_CODE: "", ADDR: null}
  const [release, setRelease] = React.useState('');
  // const [releases, setReleases] = React.useState("");
  // const [hasRelease, setHasRelease] = React.useState(false);
  // const [allowSelect, setAllowSelect] = React.useState(false);
  const [invoice_no, setInvoice_no] = useState('');
  const [supplierId, setSupplierId] = useState(null);
  const [deliveryType, setDeliveryType] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');
  const [delDate, setDelDate] = useState('');
  const [loading, setLoading] = React.useState(false);
  const [filterData, setFilterData] = React.useState([]);
  const [print, setPrint] = React.useState(false);
  const [jobList, setJobList] = React.useState(['']);

  const getSites = (company, vendor_id) =>
    getVendorSites(company, vendor_id).then(async (res) => {
      let result = await res.json();
      // console.log(result);
      let choiceSites =
        result.length === 0 ? [{ VENDOR_SITE_CODE: '', ADDR: null }] : result;
      setState((state) => ({ ...state, sites: choiceSites }));
    });

  const handleDateChange = (date) => {
    let selectedDate = date.target.value;
    console.log('date', selectedDate);
    if (!delDate) {
      // error del before invoice
      return enqueueSnackbar('กรุณาเลือกวันที่จัดส่งก่อน', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
    // check with delivery date
    if (delDate && new moment(delDate) < new moment(selectedDate)) {
      // error del before invoice
      return enqueueSnackbar(
        'ไม่สามารถเลือกวันที่ออกใบส่งของ (invoice) หลังจากวันที่จัดส่ง',
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        }
      );
    } else {
      // console.log(date.target.value);
      setInvoiceDate(selectedDate);
      return setState((state) => ({ ...state, invoiceDate: selectedDate }));
    }
  };

  const handleDelDateChange = (date) => {
    let selectedDate = date.target.value;
    // console.log('date', selectedDate);
    if (delDate && new moment(invoiceDate) > new moment(selectedDate)) {
      // error del before invoice
      return enqueueSnackbar(
        'ไม่สามารถเลือกวันที่จัดส่งก่อนวันที่ออกใบส่งของ (invoice)',
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        }
      );
    } else {
      setDelDate(selectedDate);
      setState((state) => ({ ...state, deliveryDate: selectedDate }));
    }
  };

  const handleSearchPO = async () => {
    // clear old state
    await setState((state) => ({
      ...state,
      poSelect: [],
      delAmount: [],
      qtyLot: [],
      lotSup: [],
      lotDetails: [],
    }));
    // console.log('call');
    // setState(state => ({...state, poSelect: []}))

    let uniqueJob = _.uniq(jobList);
    if (jobList.length !== uniqueJob.length) {
      return enqueueSnackbar('Duplicated Job No.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
    if (!company && !(getUserType() === 'admin' || getUserType() === 'store')) {
      setFilterData([]);
      return enqueueSnackbar('กรุณากรอกข้อมูล invoice to', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }

    console.log(
      'Job list',
      jobList,
      _.some(jobList, (e) => !e)
    );
    if (_.some(jobList, (e) => !e)) {
      setFilterData([]);
      return enqueueSnackbar('กรุณากรอกข้อมูล JOB no', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }

    if (
      (getUserType() === 'admin' || getUserType() === 'store') &&
      !supplierId
    ) {
      return enqueueSnackbar('No Supplier found', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }

    // console.log(state);
    setLoading(true);
    // start query JOB request
    // combine mutiple job TODO
    let allJOBs = _.map(jobList, async (each) => {
      return await getOSPJobDetails({
        vendor_id: vendor_id(), //"100099", //
        job: each, //"OSP1-5572076", //
        inv_orgid: company.id, //"175", //
      });
    });
    let allJOBDetails = _.map(jobList, async (each) => {
      return await getJobDetails({
        jobno: each, //"OSP1-5572076", //
        inv_orgid: company.id, //"175", //
      });
    });

    let JobDetails = await Promise.all(allJOBDetails).then((res) => res);
    console.log(JobDetails);

    // filter only select po_no
    setFilterData([]);
    setLoading(true);
    let POs = {};
    POs.Res = [];
    let allErrors;
    allErrors = _.filter(JobDetails, (each) => each.Status !== 'Success');
    console.log(allErrors);
    if (_.some(JobDetails, (each) => each.Status !== 'Success')) {
      setLoading(false);
      return enqueueSnackbar(`${allErrors[0].Message}`, {
        variant: 'error',
        autoHideDuration: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
    let anyError = false;
    await Promise.all(allJOBs).then((res) => {
      console.log(res);
      allErrors = _.filter(res, (each) => each.Status !== 'Success');
      if (_.some(res, (each) => each.Status !== 'Success')) {
        anyError = true;
        return;
      }
      if (!anyError) {
        _.map(res, (item, index) => {
          console.log(item);
          POs.Res.push({
            ...item.Res[0],
            item_code_int: JobDetails[index].Res[0].ITEM_CODE,
          });
        });
      }
    });
    console.log(allErrors);
    if (anyError) {
      setLoading(false);
      return enqueueSnackbar(`${allErrors[0].Message}`, {
        variant: 'error',
        autoHideDuration: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
    setLoading(false);
    console.log(POs.Res);
    if (!POs.Res)
      return enqueueSnackbar('ไม่พบ PO นี้', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    console.log(state.currentTransits);
    // check with on filter PO
    let data = [];

    // need top open later TODO
    if (!(getUserType() === 'admin' || getUserType() === 'store')) {
      let Outputs = _.filter(POs.Res, (item) => item.INV_ORGID === company.id);
      if (Outputs.length === 0) {
        return enqueueSnackbar('ไม่พบรายการ[1]', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    }
    POs.Res.map(async (po, ind) => {
      po.transit = 0;
      await state.currentTransits.map((transits) => {
        // console.log(transits)
        return transits.details.poSelect.map((transit, index) => {
          // append transit field to data
          if (
            po.PO_NUMBER === transit.PO_NUMBER &&
            po.RELEASE_NUM === transit.RELEASE_NUM &&
            po.LINE_NUM === transit.LINE_NUM &&
            po.SHIPMENT_NUM === transit.SHIPMENT_NUM
          ) {
            console.log(transit);
            po.transit =
              po.transit + parseFloat(transits.details.delAmount[index]);
          }
        });
      });
      data.push(po);
      // console.log(data);
      setFilterData(data);
      if (data[0]) return getSites(data[0].ORG_ID, vendor_id());
    });
    return;
  };

  const handlePrint = async () => {
    // check existing dup PO
    let dup = await checkDup(invoice_no.replace(/ /g, ''));
    // console.log(dup);
    if (dup !== 0)
      return enqueueSnackbar('Invoice No. is already exist.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });

    // check if po/do number have input
    // console.log(state);
    if (!deliveryType)
      return enqueueSnackbar('Please select type of delivery', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });

    if (!invoice_no)
      return enqueueSnackbar('Please filled Invoice number', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    if (!invoiceDate)
      return enqueueSnackbar('Please select Date', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    if (!site)
      return enqueueSnackbar('Please select site', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    if (state.poSelect.length === 0)
      return enqueueSnackbar('Please select POs', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    if (!delDate)
      return enqueueSnackbar('Please select delivery date', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    if (state.delAmount.length !== state.poSelect.length)
      return enqueueSnackbar('Please fill delivery amount', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });

    // check empty delivery
    console.log('del', state.delAmount, state.poSelect);
    if (state.delAmount.includes(null))
      return enqueueSnackbar('กรุณากรอกข้อมูล delivery amount', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });

    if (state.qtyLot.length !== state.poSelect.length)
      return enqueueSnackbar('กรุณากรอกข้อมูล QTY Pack', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    if (state.lotDetails.length !== state.poSelect.length)
      return enqueueSnackbar('กรุณากรอกข้อมูล QTY del.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    if (
      state.qtyLot.length === 1 &&
      (state.qtyLot[0] === 'null' ||
        state.qtyLot[0] === '' ||
        state.qtyLot[0] === 0)
    )
      return enqueueSnackbar('QTY Lot is invalid', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    let check_transit_qry = await check_transit_job();
    // console.log(check_transit_qry);
    if (!check_transit_qry) {
      setPrint(true);
    }
    // setPrint(true);
  };

  const check_transit_job = async () => {
    let error = false;
    /// get data transit for check qty befor print pdf
    // let POs;
    // if (getUserType() === 'admin' || getUserType() === 'store') {
    //   console.log('supplier: ', supplierId);
    //   POs = await searchPOBlk(supplierId, po_no, release).then((res) => res);
    // } else {
    //   POs = await searchPOBlk(vendor_id(), po_no, release).then((res) => res);
    // }
    // console.log(POs.Output);
    // if (!POs.Output) {
    //   error = true;
    //   return enqueueSnackbar('ไม่พบ PO นี้', {
    //     variant: 'error',
    //     anchorOrigin: {
    //       vertical: 'bottom',
    //       horizontal: 'right',
    //     },
    //   });
    // }
    // console.log(state.currentTransits);
    // // check with on filter PO
    // let data = [];
    // let inv = [];
    // if (!(getUserType() === 'admin' || getUserType() === 'store')) {
    //   let Outputs = _.filter(
    //     POs.Output,
    //     (item) => item.INV_ORGID === company.id
    //   );
    //   if (Outputs.length === 0) {
    //     error = true;
    //     return enqueueSnackbar('ไม่พบรายการ', {
    //       variant: 'error',
    //       anchorOrigin: {
    //         vertical: 'bottom',
    //         horizontal: 'right',
    //       },
    //     });
    //   }
    // }
    // let transits_data = await transits().then((res) => {
    //   let result = res.json();
    //   console.log(result);
    //   return result;
    // });
    // console.log(transits_data);
    // _.map(POs.Output, async (po, ind) => {
    //   if (po.ITEM_TYPE === 'OP') {
    //     error = true;
    //     return enqueueSnackbar('PO นี้เป็นงาน OSP', {
    //       variant: 'error',
    //       anchorOrigin: {
    //         vertical: 'bottom',
    //         horizontal: 'right',
    //       },
    //     });
    //   }

    //   // console.log(state.currentTransits);
    //   po.transit = 0;
    //   if (transits_data.length !== 0) {
    //     transits_data.map((transits) => {
    //       // console.log(transits)
    //       return transits.details.poSelect.map((transit, index) => {
    //         // append transit field to data
    //         if (
    //           po.PO_NUMBER === transit.PO_NUMBER &&
    //           po.RELEASE_NUM === transit.RELEASE_NUM &&
    //           po.LINE_NUM === transit.LINE_NUM &&
    //           po.SHIPMENT_NUM === transit.SHIPMENT_NUM
    //         ) {
    //           console.log(transit);
    //           po.transit =
    //             po.transit + parseFloat(transits.details.delAmount[index]);
    //           inv.push(transits.details.invoiceNo);
    //         }
    //       });
    //     });
    //     data.push(po);
    //     // console.log(data);
    //   }
    // });

    let allJOBs = _.map(jobList, async (each) => {
      return await getOSPJobDetails({
        vendor_id: vendor_id(), //"100099", //
        job: each, //"OSP1-5572076", //
        inv_orgid: company.id, //"175", //
      });
    });
    let allJOBDetails = _.map(jobList, async (each) => {
      return await getJobDetails({
        jobno: each, //"OSP1-5572076", //
        inv_orgid: company.id, //"175", //
      });
    });

    let JobDetails = await Promise.all(allJOBDetails).then((res) => res);
    console.log(JobDetails);

    // filter only select po_no
    setLoading(true);
    let POs = {};
    POs.Res = [];
    let allErrors;
    allErrors = _.filter(JobDetails, (each) => each.Status !== 'Success');
    console.log(allErrors);
    if (_.some(JobDetails, (each) => each.Status !== 'Success')) {
      setLoading(false);
      error = true;
      return enqueueSnackbar(`${allErrors[0].Message}`, {
        variant: 'error',
        autoHideDuration: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
    let anyError = false;
    await Promise.all(allJOBs).then((res) => {
      console.log(res);
      allErrors = _.filter(res, (each) => each.Status !== 'Success');
      if (_.some(res, (each) => each.Status !== 'Success')) {
        anyError = true;
        error = true;
        return;
      }
      if (!anyError) {
        _.map(res, (item, index) => {
          console.log(item);
          POs.Res.push({
            ...item.Res[0],
            item_code_int: JobDetails[index].Res[0].ITEM_CODE,
          });
        });
      }
    });
    console.log(allErrors);
    if (anyError) {
      setLoading(false);
      error = true;
      return enqueueSnackbar(`${allErrors[0].Message}`, {
        variant: 'error',
        autoHideDuration: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
    setLoading(false);
    console.log(POs.Res);
    if (!POs.Res) {
      error = true;
      return enqueueSnackbar('ไม่พบ PO นี้', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }

    console.log(state.currentTransits);
    // check with on filter PO
    let data = [];
    let inv = [];
    // need top open later TODO
    if (!(getUserType() === 'admin' || getUserType() === 'store')) {
      let Outputs = _.filter(POs.Res, (item) => item.INV_ORGID === company.id);
      if (Outputs.length === 0) {
        error = true;
        return enqueueSnackbar('ไม่พบรายการ[2]', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    }
    let transits_data = await transits().then((res) => {
      let result = res.json();
      console.log(result);
      return result;
    });
    POs.Res.map(async (po, ind) => {
      po.transit = 0;
      if (transits_data.length !== 0) {
        transits_data.map((transits) => {
          // console.log(transits)
          return transits.details.poSelect.map((transit, index) => {
            // append transit field to data
            if (
              po.PO_NUMBER === transit.PO_NUMBER &&
              po.RELEASE_NUM === transit.RELEASE_NUM &&
              po.LINE_NUM === transit.LINE_NUM &&
              po.SHIPMENT_NUM === transit.SHIPMENT_NUM
            ) {
              console.log(transit);
              po.transit =
                po.transit + parseFloat(transits.details.delAmount[index]);
              inv.push(transits.details.invoiceNo);
            }
          });
        });
        data.push(po);
        // console.log(data);
      }
    });
    console.log(data);
    console.log(inv, invoice_no);
    if (inv.includes(invoice_no)) {
      error = true;
      return enqueueSnackbar('มีเลข Invoice นี้อยู่แล้ว', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
    // console.log(state);
    let data_filter = [];
    _.map(state.poSelect, (po) => {
      _.map(data, (delivery) => {
        console.log(po, delivery);
        if (po.ITEM_CODE === delivery.ITEM_CODE && po.JOBS === delivery.JOBS) {
          data_filter.push(delivery);
        }
      });
    });
    console.log(data_filter, state.poSelect);
    _.map(data_filter, (item, index) => {
      console.log(
        'sum qty : ' +
          (parseFloat(item.transit) + parseFloat(state.delAmount[index])) +
          ' : ' +
          parseFloat(item.QUANTITY)
      );
      if (
        parseFloat(item.transit) + parseFloat(state.delAmount[index]) >
        parseFloat(item.QUANTITY)
      ) {
        error = true;
        return enqueueSnackbar(
          `ITEM ${item.ITEM_CODE} จำนวนส่งเกิน PO โปรดตรวจสอบที่หน้า Delivery`,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          }
        );
      }
    });
    return error;
  };

  const handleReset = () => {
    setInvoice_no('');
    setInvoiceDate('');
    setSite('');
  };

  const handleAddJob = () => {
    let currentJob = [...jobList, ''];
    setJobList(currentJob);
  };

  const handleRemoveJob = (index) => {
    let currentJob = [...jobList];
    currentJob.splice(index, 1);
    setJobList(currentJob);
  };

  const handleJobInput = (e, index) => {
    let currentJob = [...jobList];
    currentJob[index] = e.target.value;
    setJobList(currentJob);
  };

  const handleClose = () => setPrint(false);

  // const testAPI = () => {
  //     getOSPJobDetails({
  //         "vendor_id": "100099",
  //         "job": "OSP1-5572076",
  //         "inv_orgid": "175"
  //     })
  // };

  useEffect(() => {}, [filterData]);

  useEffect(() => {}, [state]);

  useEffect(() => {
    // if user is internal set company by user
    if (getUserType() === 'internal') {
      setCompany(company_name());
    }
  }, []);

  // console.log(state);
  // console.log(company);

  if (!state.companies)
    return <ReactLoading type={'bars'} color={'#444'} className="preloader" />;

  if (
    !state.vendors &&
    (getUserType() === 'admin' || getUserType() === 'store')
  )
    return <ReactLoading type={'bars'} color={'#444'} className="preloader" />;

  if (print)
    return (
      <DeliveryPDF
        print={print}
        handleClose={handleClose}
        history={props.history}
        jobList={jobList}
        check_transit_job={check_transit_job}
      />
    );

  return (
    <Grid className={classes.root} component="span">
      {getUserType() !== 'admin' ? (
        <Grid container spacing={1}>
          <Grid item xs={12} md={5}>
            <Paper className={classes.paper} style={{ paddingBottom: 13 }}>
              <Grid container spacing={2}>
                {getUserType() === 'store' ? (
                  <>
                    <Grid item xs={4}>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        disabled
                        value={company_name()}
                        label={'Invoice to'}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={state.vendors}
                        ListboxComponent={ListboxComponent}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Supplier"
                            variant="outlined"
                          />
                        )}
                        size="small"
                        onChange={(e, value) => {
                          console.log(value);
                          if (value) return setSupplierId(value.id);
                        }}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <Autocomplete
                      size="small"
                      id="controllable-states-demo"
                      options={state.companies} //state.companies.map(company => company.name)
                      value={company}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newValue) => {
                        setCompany(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Invoice to"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                )}
                {jobList.length > 0 &&
                  jobList.map((job, index) => (
                    <>
                      <Grid item xs={7}>
                        <div className="po-number">
                          <TextField
                            name="job_no"
                            value={job}
                            onChange={(e) => handleJobInput(e, index)}
                            size="small"
                            id="outlined-basic"
                            label="JOB No"
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                      </Grid>
                      {index === 0 && (
                        <Grid item xs={5} spacing={2}>
                          <Button
                            style={{ float: 'right', marginLeft: '5px' }}
                            variant="contained"
                            color="primary"
                            onClick={handleSearchPO}
                          >
                            Search
                          </Button>
                          <Button
                            style={{ float: 'right' }}
                            variant="contained"
                            color="primary"
                            onClick={handleAddJob}
                          >
                            ADD
                          </Button>
                        </Grid>
                      )}
                      {index !== 0 && (
                        <Grid item xs={5} spacing={2}>
                          <Button
                            style={{ float: 'right', marginLeft: '5px' }}
                            variant="contained"
                            color="secondary"
                            onClick={() => handleRemoveJob(index)}
                          >
                            Remove
                          </Button>
                        </Grid>
                      )}
                    </>
                  ))}
                {/* <Grid item xs={5}>
                                    <div className="po-number">
                                        <TextField
                                            name="PO_no"
                                            value={po_no}
                                            onChange={(event) => {
                                                setPo_no(event.target.value);
                                            }}
                                            size="small"
                                            id="outlined-basic"
                                            label="PO No"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        name="release"
                                        value={release}
                                        onChange={(event) => {
                                            setRelease(event.target.value);
                                        }}
                                        size="small"
                                        id="outlined-basic"
                                        label="Rel."
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid> */}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={7}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid container spacing={2} style={{ marginBottom: 5 }}>
                  <Grid item xs={3}>
                    <Autocomplete
                      size="small"
                      value={deliveryType}
                      onChange={(event, newValue) => {
                        setDeliveryType(newValue);
                        setState((state) => ({
                          ...state,
                          isInvoice: newValue === 'Invoice' ? true : false,
                        }));
                      }}
                      id="controllable-states-demo"
                      options={['Invoice', 'DO']}
                      // getOptionLabel={opt=>opt.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Type"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      value={invoice_no}
                      onChange={(e) => {
                        let input = e.target.value;
                        setInvoice_no(input.trim());
                        setState((state) => ({ ...state, invoiceNo: input }));
                      }}
                      label={state.isInvoice ? 'Invoice No.' : 'DO No.'}
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      placeholder="Invoice ใบเดียวเท่านั้น"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      label={'Delivery Date'}
                      value={delDate}
                      inputProps={{
                        onKeyDown: (event) => {
                          event.preventDefault();
                        },
                      }}
                      onChange={handleDelDateChange}
                      variant="outlined"
                      type="date"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      label={state.isInvoice ? 'Invoice Date' : 'DO Date'}
                      value={invoiceDate}
                      inputProps={{
                        onKeyDown: (event) => {
                          event.preventDefault();
                        },
                      }}
                      onChange={handleDateChange}
                      variant="outlined"
                      type="date"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  {getUserType() !== 'store' ? (
                    <Grid item xs={4}>
                      <Autocomplete
                        size="small"
                        value={site}
                        onChange={(event, newValue) => {
                          // console.log(newValue)
                          setSite(newValue);
                          setState((state) => ({ ...state, site: newValue }));
                        }}
                        id="controllable-states-demo"
                        options={state.sites}
                        getOptionLabel={(opt) => {
                          if (!opt.ADDR) return opt.VENDOR_SITE_CODE;
                          return `${opt.VENDOR_SITE_CODE} [${opt.ADDR}]`;
                        }}
                        renderOption={(opt, params) => {
                          // console.log(opt);
                          // return <Chip label={opt.VENDOR_SITE_CODE}></Chip>
                          return (
                            <ListItemText
                              primary={opt.VENDOR_SITE_CODE}
                              secondary={
                                opt.ADDR ? opt.ADDR.toLowerCase() : null
                              }
                            />
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Site"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={4}></Grid>
                  )}
                  <Grid item xs={4}>
                    <Grid container spacing={1} justify="flex-end">
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handlePrint}
                        >
                          Preview
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <ReactLoading
                type={'bars'}
                color={'#444'}
                className="preloader"
              />
            ) : (
              <Table remoteData={filterData} history={props.history} />
            )}
          </Grid>
        </Grid>
      ) : (
        <>
          <Paper className={classes.paper}>
            <Grid container spacing={1}>
              <Grid item xs={9} md={3}>
                <TextField
                  name="PO_no"
                  value={po_no}
                  onChange={(event) => {
                    setPo_no(event.target.value);
                  }}
                  size="small"
                  id="outlined-basic"
                  label="PO No"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3} md={4}>
                <TextField
                  name="release"
                  value={release}
                  onChange={(event) => {
                    setRelease(event.target.value);
                  }}
                  size="small"
                  id="outlined-basic"
                  label="Rel. No"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={9} md={4}>
                <Autocomplete
                  id="combo-box-demo"
                  options={state.vendors}
                  ListboxComponent={ListboxComponent}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Supplier"
                      variant="outlined"
                    />
                  )}
                  size="small"
                  onChange={(e, value) => {
                    console.log(value);
                    if (value) return setSupplierId(value.id);
                  }}
                />
              </Grid>
              <Grid item xs={3} md={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSearchPO}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Paper>
          <Grid item xs={12}>
            {loading ? (
              <ReactLoading
                type={'bars'}
                color={'#444'}
                className="preloader"
              />
            ) : (
              <Table remoteData={filterData} history={props.history} />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}
