import React, { useEffect } from 'react';
import List from '@material-ui/core/List';
import DescriptionIcon from '@material-ui/icons/Description';
import { useHistory } from 'react-router-dom';
import { BlueListItem } from '../components';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PeopleIcon from '@material-ui/icons/People';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PrintIcon from '@material-ui/icons/Print';
import { getUserType } from '../utils';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import BallotIcon from '@material-ui/icons/Ballot';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PermDeviceInformationIcon from '@material-ui/icons/PermDeviceInformation';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import { DataContext } from '../ContextData';
import settings from '../config';

const TextSidebar = () => {
  const history = useHistory();

  // console.log(history.location.pathname);
  const [state, setState] = React.useContext(DataContext);
  const [open, setOpen] = React.useState(false);
  const [openPurchase, setOpenPurchase] = React.useState(false);
  const [openAccount, setOpenAccount] = React.useState(true);
  const [openPC, setOpenPC] = React.useState(false);
  const [active, setActive] = React.useState('Confirm PO');

  const selectMenu = () => {
    if (history.location.pathname === '/dashboard') {
      setActive('Dashboard');
    } else if (history.location.pathname === '/') {
      setActive('Confirm PO');
    } else if (history.location.pathname === '/table') {
      setActive('Delivery Status');
    } else if (history.location.pathname === '/pds-status') {
      setActive('PO Blanket/PDS Pending');
    } else if (history.location.pathname === '/print-received') {
      setActive('Print Received No');
    } else if (history.location.pathname === '/scan-received') {
      setActive('Scan to received');
    } else if (history.location.pathname === '/user') {
      setActive('Manage User');
    } else if (history.location.pathname === '/invoice-admin') {
      setActive('Billing Invoices');
    } else if (history.location.pathname === '/payment-admin') {
      setActive('Confirmed Payments');
    } else if (history.location.pathname === '/account-info') {
      setActive('Account Information');
    } else if (history.location.pathname === '/store-info') {
      setActive('Store Information');
    } else if (history.location.pathname === '/confirm-po') {
      setActive('Upload/Download Order');
    } else if (history.location.pathname === '/confirm-asn') {
      setActive('Confirm ASN');
    } else if (history.location.pathname === '/email-group') {
      setActive('Email Group');
    } else if (history.location.pathname === '/forecast') {
      setActive('Forecast');
    } else if (history.location.pathname === '/manual') {
      setActive('User Manual');
    } else if (history.location.pathname === '/purchase') {
      setActive('Upload/Download SQM');
    } else if (history.location.pathname === '/codeOfconduct') {
      setActive('codeOfconduct');
    } else {
      setActive(null);
    }
  };

  React.useEffect(() => {
    selectMenu();
  }, [history.location.pathname]);

  const handleClick = () => {
    setOpen(!open);
  };
  const list = [
    {
      primaryText: 'User Manual',
      icon: <DescriptionIcon />,
      onClickItem: (name) => {
        // set active to true
        setActive('User Manual');
        history.push('/manual');
      },
    },
  ];

  const list1 = [
    {
      primaryText: 'Confirm PO',
      icon: <DescriptionIcon />,
      onClickItem: (name) => {
        // set active to true
        setActive('Confirm PO');
        history.push('/');
      },
    },
    {
      primaryText: 'Delivery Status',
      icon: <LocalShippingIcon />,
      onClickItem: (name) => {
        // set active to true
        setActive('Delivery Status');
        history.push('/table');
      },
    },
    {
      primaryText: 'PO Blanket/PDS Pending',
      icon: <PlaylistAddCheckIcon />,
      onClickItem: (name) => {
        setActive('PO Blanket/PDS Pending');
        // set active to true
        history.push('/pds-status');
      },
    },
  ];

  // if (getUserType() === 'store') {
  //   list.push({
  //     primaryText: 'Manage User',
  //     icon: <PeopleIcon />,
  //     onClickItem: (name) => {
  //       // set active to true
  //       setActive('Manage User');
  //       history.push('/user');
  //     },
  //   });
  // }
  let list2 = [];
  if (getUserType().includes('account')) {
    list2 = [
      {
        primaryText: 'Billing Invoices',
        icon: <ReceiptIcon />,
        onClickItem: (name) => {
          // set active to true
          setActive('Billing Invoices');
          history.push('/invoice-admin');
        },
      },
      {
        primaryText: 'Confirmed Payments',
        icon: <SpellcheckIcon />,
        onClickItem: (name) => {
          // set active to true
          setActive('Confirmed Payments');
          history.push('/payment-admin');
        },
      },
      {
        primaryText: 'Account Information',
        icon: <PermDeviceInformationIcon />,
        onClickItem: (name) => {
          // set active to true
          setActive('Account Information');
          history.push('/account-info');
        },
      },
    ];
  }
  const list3 = [];
  list3.push(
    {
      primaryText: 'Forecast',
      icon: <TrendingUpIcon />,
      onClickItem: (name) => {
        // set active to true
        setActive('Forecast');
        history.push('/forecast');
      },
    },
    {
      primaryText: 'Upload/Download Order',
      icon: <BallotIcon />,
      onClickItem: (name) => {
        // set active to true
        setActive('Upload/Download Order');
        history.push('/confirm-po');
      },
    },
    {
      primaryText: 'Confirm ASN',
      icon: <DoneAllIcon />,
      onClickItem: (name) => {
        // set active to true
        setActive('Confirm ASN');
        history.push('/confirm-asn');
      },
    },
    {
      primaryText: 'Store Information',
      icon: <PermDeviceInformationIcon />,
      onClickItem: (name) => {
        // set active to true
        setActive('Store Information');
        history.push('/store-info');
      },
    }
  );

  // purchase //
  const list5 = [];
  list5.push(
    {
      primaryText: 'Upload/Download SQM',
      icon: <BallotIcon />,
      onClickItem: (name) => {
        // set active to true
        setActive('Upload/Download SQM');
        history.push('/purchase');
      },
    },
    {
      primaryText: 'Supplier Code of Conduct',
      icon: <BallotIcon />,
      onClickItem: (name) => {
        // set active to true
        setActive('codeOfconduct');
        history.push('/codeOfconduct');
      },
    },
    {
      primaryText: 'Whistle blowing policy',
      icon: <BallotIcon />,
      onClickItem: (name) => {
        // set active to true
        setActive('codeOfconduct');
        history.push('/codeOfconduct');
      },
    },
    {
      primaryText: 'Anti-Corruption policy',
      icon: <BallotIcon />,
      onClickItem: (name) => {
        // set active to true
        setActive('codeOfconduct');
        history.push('/codeOfconduct');
      },
    }
  );

  // }
  if (getUserType() === 'store') {
    list3.push({
      primaryText: 'Email Group',
      icon: <ContactMailIcon />,
      onClickItem: (name) => {
        // set active to true
        setActive('Email Group');
        history.push('/email-group');
      },
    });
  }
  if (getUserType() === 'admin') {
    // list.push(
    // {
    //   primaryText: 'PDS Pending',
    //   icon: <PlaylistAddCheckIcon />,
    //   onClickItem: (name) => {
    //     setActive("PDS Status")
    //     // set active to true
    //     history.push('/pds-status')
    //   }
    // });
    // list.push({
    //   primaryText: 'Forecast',
    //   icon: <TrendingUpIcon />,
    //   onClickItem: (name) => {
    //     // set active to true
    //     setActive('Forecast');
    //     history.push('/forecast');
    //   },
    // });

    list1.unshift({
      primaryText: 'Dashboard',
      icon: <EqualizerIcon />,
      onClickItem: (name) => {
        // set active to true
        setActive('Dashboard');
        history.push('/dashboard');
      },
    });
  }
  // list.push({
  //   primaryText: 'Print Received No',
  //   icon: <PrintIcon />,
  //   onClickItem: (name) => {
  //     // set active to true
  //     setActive('Print Received No');
  //     history.push('/print-received');
  //   },
  // });
  // list.push({
  //   primaryText: 'Scan to received',
  //   icon: <SpellcheckIcon />,
  //   onClickItem: (name) => {
  //     // set active to true
  //     setActive('Scan to received');
  //     history.push('/scan-received');
  //   },
  // });
  let list4 = [];
  list4.push({
    primaryText: 'Manage User',
    icon: <PeopleIcon />,
    onClickItem: (name) => {
      // set active to true
      setActive('Manage User');
      history.push('/user');
    },
  });

  // if(getUserType() === "vendor") {
  //   list.push(
  //     {
  //       primaryText: 'Forecast',
  //       icon: <TrendingUpIcon />,
  //       onClickItem: (name) => {
  //         // set active to true
  //         history.push('/forecast')
  //       }
  //   });
  // }
  const getNewVersion = async () => {
    await fetch(`${settings.server}/softwares`, {
      method: 'GET',
      // headers: new Headers({ 'Content-Type': 'application/json' }),
    }).then(async (res) => {
      let data = await res.json();
      if (data[0].version !== localStorage.getItem('VERSION')) {
        localStorage.setItem('VERSION', data[0].version);
        window.location.reload();
      }
      // console.log(data[0].version);
    });
  };
  useEffect(() => {
    console.log('update version', localStorage);
    getNewVersion();
  }, [active]);

  console.log(active);
  return (
    <List>
      {!getUserType().includes('account') && (
        <>
          <BlueListItem
            accentColor={'#11fedc'}
            primaryText={
              <span style={{ fontSize: 16, weight: 'bold' }}>
                Delivery Data
              </span>
            }
            icon={
              open ? (
                <ExpandLess style={{ color: '#11fedc' }} />
              ) : (
                <ExpandMore style={{ color: '#11fedc' }} />
              )
            }
            onClickItem={handleClick}
          ></BlueListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {list1.map((item) => (
                <div className="ml-3">
                  <BlueListItem
                    accentColor={'#11fedc'}
                    key={item.primaryText}
                    {...item}
                    active={item.primaryText === active ? true : false}
                  />
                </div>
              ))}
            </List>
          </Collapse>
        </>
      )}
      {getUserType().includes('account') && (
        <>
          <BlueListItem
            accentColor={'#11fedc'}
            primaryText={
              <span style={{ fontSize: 16, weight: 'bold' }}>
                Accounting Data
              </span>
            }
            icon={
              openAccount ? (
                <ExpandLess style={{ color: '#11fedc' }} />
              ) : (
                <ExpandMore style={{ color: '#11fedc' }} />
              )
            }
            onClickItem={() => setOpenAccount(!openAccount)}
          ></BlueListItem>
          <Collapse in={openAccount} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {list2.map((item) => (
                <div className="ml-3">
                  <BlueListItem
                    accentColor={'#11fedc'}
                    key={item.primaryText}
                    {...item}
                    active={item.primaryText === active ? true : false}
                  />
                </div>
              ))}
            </List>
          </Collapse>
        </>
      )}
      {!getUserType().includes('account') && (
        <>
          <BlueListItem
            accentColor={'#11fedc'}
            primaryText={
              <span style={{ fontSize: 16, weight: 'bold' }}>PC Data</span>
            }
            icon={
              openPC ? (
                <ExpandLess style={{ color: '#11fedc' }} />
              ) : (
                <ExpandMore style={{ color: '#11fedc' }} />
              )
            }
            onClickItem={() => setOpenPC(!openPC)}
          ></BlueListItem>
          <Collapse in={openPC} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {list3.map((item) => (
                <div className="ml-3">
                  <BlueListItem
                    accentColor={'#11fedc'}
                    key={item.primaryText}
                    {...item}
                    active={item.primaryText === active ? true : false}
                  />
                </div>
              ))}
            </List>
          </Collapse>
        </>
      )}

      {/* peelawit 06/01/66 */}
      {!getUserType().includes('account') && (
        <>
          <BlueListItem
            accentColor={'#11fedc'}
            primaryText={
              <span style={{ fontSize: 16, weight: 'bold' }}>Purchase</span>
            }
            icon={
              openPurchase ? (
                <ExpandLess style={{ color: '#11fedc' }} />
              ) : (
                <ExpandMore style={{ color: '#11fedc' }} />
              )
            }
            onClickItem={() => setOpenPurchase(!openPurchase)}
          ></BlueListItem>
          <Collapse in={openPurchase} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {list5.map((item) => (
                <div className="ml-3">
                  <BlueListItem
                    accentColor={'#11fedc'}
                    key={item.primaryText}
                    {...item}
                    active={item.primaryText === active ? true : false}
                  />
                </div>
              ))}
            </List>
          </Collapse>
        </>
      )}

      {list.map((item) => (
        <BlueListItem
          accentColor={'#11fedc'}
          key={item.primaryText}
          {...item}
          active={item.primaryText === active ? true : false}
        />
      ))}

      {getUserType().includes('admin') &&
        list4.map((item) => (
          <div>
            <BlueListItem
              accentColor={'#11fedc'}
              key={item.primaryText}
              {...item}
              active={item.primaryText === active ? true : false}
            />
          </div>
        ))}
    </List>
  );
};

export default TextSidebar;
